// Here you can add other styles

.info-area {
  font-size: 12px;
  .box-area {
    padding: 0px 15px;
  }
  .border-right {
    border-right: 1px solid black;
  }
}

.border-radius-none {
  border-radius: 0px;
}

.main_login_page {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: radial-gradient(white, #218dbf);

  #border-none {
    border: 0px !important;
  }
  @media (min-width: 768px) {
    height: 100vh;
    .form_login {
      display: flex;
      flex-direction: row;
      background-color: #fff;
      border-radius: 10px;
      padding-block: 10vh;
      padding-inline: 2vh;
      width: 70%;
      justify-content: space-evenly;
      #border-hide {
        border: 0px !important;
      }
    }
  }
  @media (max-width: 768px) {
    .form_login {
      display: grid;
      flex-direction: column;
      background-color: #fff;
      width: 100%;
      justify-content: center;
    }
  }

  .login_button_action {
    padding: 10px 0px 10px 0px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
}

.ifootcenter {
  @media (min-width: 768px) {
    margin-left: -20px;
    margin-bottom: -2px;
  }
  @media (max-width: 768px) {
    margin-bottom: -8px;
  }
}

.ifootleft {
  border-style: groove;
  border-color: white;
  border-width: 2px;
  margin-right: 0px;
  margin-left: 0px;
  @media (min-width: 768px) {
    padding-bottom: 100px;
  }
  @media (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.VMToggleDropDown {
  border: 1px solid;
  background-color: #fff;
  border-color: #d8dbe0;
  width: auto;
  height: auto;
}

.VMToggleDropDown.tbl {
  padding: 0.25rem 0.5rem;
  font-size: 0.765625rem;
  color: #768192;
}

.VMDropdownMenu {
  overflow-y: scroll;
  max-height: 12em;
}

.VMPendingCheck {
  border: 1px solid;
  color: #ffffff;
  background-color: #00a65a;
  border-color: #008d4c;
  width: auto;
  height: auto;
  padding: 3px 5px;
  margin: 2.5px;
  font-size: 0.765625rem;
  border-radius: 2.5px;
}

.VMPendingRemove {
  border: 1px solid;
  color: #ffffff;
  background-color: #dd4b39;
  border-color: #d73925;
  width: auto;
  height: auto;
  padding: 3px 5px;
  margin: 2.5px;
  font-size: 0.765625rem;
  border-radius: 2.5px;
}

.VMCetakPenjualan {
  border: 1px solid;
  color: #ffffff;
  background-color: #f39c12;
  border-color: #e08e0b;
  width: auto;
  height: auto;
  padding: 3px 5px;
  font-size: 0.765625rem;
  border-radius: 2.5px;
}

.VMExpandPenjualan {
  border: 1.5px solid;
  color: #1a1a1a;
  background-color: #ffffff;
  border-color: #1a1a1a;
  width: auto;
  height: auto;
  padding: 3px 3px;
  font-size: 0.765625rem;
  border-radius: 3.5px;
}

.VMExpandPenjualan.open {
  -webkit-animation: r90deg 0.2s linear forwards;
  -moz-animation: r90deg 0.2s linear forwards;
  animation: r90deg 0.2s linear forwards;
}

.VMExpandPenjualan.close {
  -webkit-animation: r0deg 0.2s linear forwards;
  -moz-animation: r0deg 0.2s linear forwards;
  animation: r0deg 0.2s linear forwards;
}

.VMRowSpaceBetween {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.VMFlexCenter {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.VMFlexCenter.inherit {
  padding: inherit;
}

input:focus {
  outline: none;
}

@-moz-keyframes r90deg {
  100% {
    -moz-transform: rotate(90deg);
  }
}
@-webkit-keyframes r90deg {
  100% {
    -webkit-transform: rotate(90deg);
  }
}
@keyframes r90deg {
  100% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
}

@-moz-keyframes r0deg {
  0% {
    -moz-transform: rotate(90deg);
  }
  100% {
    -moz-transform: rotate(0deg);
  }
}
@-webkit-keyframes r0deg {
  0% {
    -webkit-transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes r0deg {
  0% {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}

.VMBtnModal {
  margin-inline: auto;
  padding-block-end: inherit;
}

.VMThChart {
  border-bottom: 2px solid;
  border-bottom-color: #d8dbe0;
  border-top: 1px solid;
  border-top-color: #d8dbe0;
  vertical-align: top;
  text-align: inherit;
  font-weight: bold;
  padding: 0.75rem;
  color: #3c4b64;
}

.VMWrapModal {
  overflow: hidden !important;
  height: -webkit-fill-available;
  margin-block: auto;
  bottom: auto;
}

.VMWrapContentModal {
  margin: auto;
}

.mb40 {
  margin-bottom: 40px;
}

.VMTable-fixed-pagination {
  position: fixed;
  bottom: 95px;
}

.importantInfo {
  width: 75%;
  margin-bottom: 0 !important;
  padding-block: 1rem !important;
  padding-inline-end: 1rem !important;
  border: 0.7px solid #d8dbe0 !important;
  border-radius: 5px;
  h4 {
    display: flex;
    justify-content: center;
    list-style-type: none;
  }
  li.fk {
    margin-top: 10px;
    color: #e55353;
    list-style-type: none;
  }
}

.infoUploadData {
  background-color: white;
  color: #39f;
  font-size: smaller;
  padding: 5px;
  border-radius: 5px;
}

#modal-open-shift{
  width: 100%;
  text-align: -webkit-center;
}

.subMenuShift {
  flex-wrap: nowrap;
  width: 75%;
  align-items: center;
  margin-block-end: 1rem !important;
  b.titleMenuShift {
    min-inline-size: fit-content;
  }
  .valueMenuShift {
    width: 90%;
  }
}
