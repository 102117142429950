body .item-sale-offline {
  overflow: hidden;
}
@media (max-width: 768px) {
  body .item-sale-offline {
    overflow: scroll;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: lightgray;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: grey;
}
/* ## Border Radius ## */
.VMRadius {
  border-radius: 10px;
}

.VMRadius-sm {
  border-radius: 5px !important;
}

input.form-control:focus {
  outline: none;
  border-color: rgba(212, 234, 255, 0.459);
  box-shadow: 0 0 3px #39f;
}

input.form-control.form-control-lg.text-right {
  font-size: 2.5rem;
}

div#content{
  background: whitesmoke;
}

p.grandTotal{
  font-size: xx-large;
}

button.d-flex.flex-row.align-items-center.text-dark.button-shortcut:focus{
  outline: none;
  border-color: rgba(212, 234, 255, 0.459);
  box-shadow: 0 0 3px #39f;
}

select.custom-select:focus {
  outline: none;
  border-color: rgba(212, 234, 255, 0.459);
  box-shadow: 0 0 3px #39f;
}

select.form-control:focus {
  outline: none;
  border-color: rgba(212, 234, 255, 0.459);
  box-shadow: 0 0 3px #39f;
}

li.VMRadius-sm {
  border-color: lightgray;
}

li.VMRadius-sm.disabled {
  cursor: not-allowed;
}

li.VMRadius-sm.passed {
  border-color: #2eb85c !important;
  color: #2eb85c !important;
}

li.VMRadius-sm.passed div svg.passed {
  color: #2eb85c !important;
}

li:not(:disabled):not(.disabled) {
  cursor: pointer;
}

li:not(:disabled):not(.disabled).loading {
  cursor: progress;
}

/* ## Modal ## */
div.modal-dialog.modal-dialog-scrollable.modal-xl,
[role="document"] {
  height: -webkit-fill-available !important;
}

.VMmodal {
  z-index: 5;
  display: table;
  padding-top: 85px;
  padding-inline: 10%;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.4);
  transform: scale(0);
}

.VMmodal.Open {
  transform: scale(1);
  animation: fadeIn 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.VMmodal.Close {
  animation: fadeOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.VMSpacerModalHead {
  padding-block: 0.5rem;
  padding-inline: 1.2rem;
  margin: 0;
}

.VMSpacerModalSub {
  padding-block: 0.5rem;
  padding-inline: 1.25rem;
  margin: 0;
}

.sm {
  max-width: 310px;
}

.lg {
  max-width: 810px;
}

.xl {
  max-width: 1150px;
}

.VMBgSpinner {
  /* background-color: rgba(0, 0, 0, 0.4); */
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  max-width: fit-content;
  max-height: fit-content;
  margin: auto;
}

.VMModalSpinner {
  z-index: 2060;
  display: inline-grid;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.Sptrnsprnt {
  background-color: rgba(255, 255, 255, 0);
}

.VMSpaceBetween {
  display: flex;
  justify-content: space-between;
}

.VMimg {
  width: 100%;
  height: auto;
  margin-bottom: 20px;
}

svg.spin {
  -webkit-animation: spin 1s linear infinite;
  -moz-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

svg.uploading {
  -webkit-animation: uploading 2s linear infinite;
  -moz-animation: uploading 2s linear infinite;
  animation: uploading 2s linear infinite;
}

div.version {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

div.netinformation {
  height: 9px;
  width: 11px;
  border-radius: 5px;
  border-style: dotted;
  border-width: 1px;
  background-color: #f9b1156d;
  background-color: #ffae006b;
}
div.netinformation.on {
  background-color: #2eb85c6d;
  border-color: #22ff006b;
}
div.netinformation.off {
  background-color: #b82e2e6d;
  border-color: #ff00006b;
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }
  100% {
    transform: scale(0);
    background: rgba(0, 0, 0, 0);
  }
}

@-moz-keyframes spin {
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes uploading {
  0% {
    -moz-transform: translateY(21px);
  }
  100% {
    -moz-transform: translateY(-21px);
  }
}
@-webkit-keyframes uploading {
  0% {
    -webkit-transform: translateY(21px);
  }
  100% {
    -webkit-transform: translateY(-21px);
  }
}
@keyframes uploading {
  0% {
    -webkit-transform: translateY(21px);
    transform: translateY(21px);
  }
  100% {
    -webkit-transform: translateY(-21px);
    transform: translateY(-21px);
  }
}
